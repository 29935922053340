import LuBusinessType from './LuBusinessType'
import License from './License'
import User from './User'
import HempLicenseArea from './HempLicenseArea'
import HempLicenseDocument from './HempLicenseDocument'
import KeyParticipants from './KeyParticipants'
export default class HempLicense {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //LicenseID is required
      LicenseID: 0,
      //BusinessTypeID is required
      BusinessTypeID: 0,
      Other: null,
      DateOfBirth: null,
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.LicenseID = obj.LicenseID
    this.BusinessTypeID = obj.BusinessTypeID
    this.Other = obj.Other
    this.DateOfBirth = obj.DateOfBirth
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.BusinessType = new LuBusinessType(obj.BusinessType || {})
    this.InsertBy = new User(obj.InsertBy || {})
    this.License = new License(obj.License || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
    this.HempLicenseArea = []
    if (obj.HempLicenseArea) {
      obj.HempLicenseArea.forEach((item) => {
        this.HempLicenseArea.push(new HempLicenseArea(item))
      })
    }
    this.HempLicenseDocument = []
    if (obj.HempLicenseDocument) {
      obj.HempLicenseDocument.forEach((item) => {
        this.HempLicenseDocument.push(new HempLicenseDocument(item))
      })
    }
    this.KeyParticipants = []
    if (obj.KeyParticipants) {
      obj.KeyParticipants.forEach((item) => {
        this.KeyParticipants.push(new KeyParticipants(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.BusinessType = null
    obj.InsertBy = null
    obj.License = null
    obj.UpdateBy = null
    obj.HempLicenseArea = []
    if (this.HempLicenseArea) {
      this.HempLicenseArea.forEach((item) => {
        obj.HempLicenseArea.push(item.removeRelated())
      })
    }
    obj.HempLicenseDocument = []
    if (this.HempLicenseDocument) {
      this.HempLicenseDocument.forEach((item) => {
        obj.HempLicenseDocument.push(item.removeRelated())
      })
    }
    obj.KeyParticipants = []
    if (this.KeyParticipants) {
      this.KeyParticipants.forEach((item) => {
        obj.KeyParticipants.push(item.removeRelated())
      })
    }
    return obj
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.BusinessType = null
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.License = this.License.removeRelated()
    // if (this.License) {
    //   this.License.forEach((item) => {
    //     obj.License.push(item.getSaveData())
    //   })
    // }
    obj.HempLicenseArea = null
    // if (this.HempLicenseArea) {
    //   this.HempLicenseArea.forEach((item) => {
    //     obj.HempLicenseArea.push(item.getSaveData())
    //   })
    // }
    obj.HempLicenseDocument = null
    // if (this.HempLicenseDocument) {
    //   this.HempLicenseDocument.forEach((item) => {
    //     obj.HempLicenseDocument.push(item.removeRelated())
    //   })
    //}
    obj.KeyParticipants = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.BusinessType = null
    obj.InsertBy = null
    obj.License = null
    obj.UpdateBy = null
    obj.HempLicenseArea = null
    obj.HempLicenseDocument = null
    return obj
  }
}