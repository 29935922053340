import User from './User'
import HempLicenseArea from './HempLicenseArea'
export default class LuHempAreaType {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Description is required
      Description: '',
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      //UpdateByID is required
      UpdateByID: null,
      //UpdateDate is required
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description = obj.Description
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.InsertBy = new User(obj.InsertBy || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
    this.HempLicenseArea = []
    if (obj.HempLicenseArea) {
      obj.HempLicenseArea.forEach((item) => {
        this.HempLicenseArea.push(new HempLicenseArea(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.HempLicenseArea = []
    if (this.HempLicenseArea) {
      this.HempLicenseArea.forEach((item) => {
        obj.HempLicenseArea.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.HempLicenseArea = null
    return obj
  }
}
