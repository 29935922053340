import HempLicense from './HempLicense'
import LuHempAreaType from './LuHempAreaType'
import LuHempOwnership from './LuHempOwnership'
import User from './User'
import HempLicenseDocument from './HempLicenseDocument'
export default class HempLicenseArea {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //HempLicenseID is required
      HempLicenseID: 0,
      //Name is required
      Name: '',
      LegalDescription: null,
      Latitude: null,
      Longitude: null,
      ProducingArea: '',
      ProcessingArea: '',
      //HempOwnershipID is required
      HempOwnershipID: 0,
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      UpdateByID: null,
      UpdateDate: null,
      AreaTypeID: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.HempLicenseID = obj.HempLicenseID
    this.Name = obj.Name
    this.LegalDescription = obj.LegalDescription
    this.Latitude = obj.Latitude
    this.Longitude = obj.Longitude
    this.ProducingArea = obj.ProducingArea
    this.ProcessingArea = obj.ProcessingArea
    this.HempOwnershipID = obj.HempOwnershipID
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.AreaTypeID = obj.AreaTypeID
    this.AreaType = new LuHempAreaType(obj.AreaType || {})
    this.HempLicense = new HempLicense(obj.HempLicense || {})
    this.HempOwnership = new LuHempOwnership(obj.HempOwnership || {})
    this.InsertBy = new User(obj.InsertBy || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
    this.HempLicenseDocument = []
    if (obj.HempLicenseDocument) {
      obj.HempLicenseDocument.forEach((item) => {
        this.HempLicenseDocument.push(new HempLicenseDocument(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.AreaType = null
    obj.HempLicense = null
    obj.HempOwnership = null
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.HempLicenseDocument = []
    if (this.HempLicenseDocument) {
      this.HempLicenseDocument.forEach((item) => {
        obj.HempLicenseDocument.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.AreaType = null
    obj.HempLicense = null
    obj.HempOwnership = null
    obj.InsertBy = null
    obj.UpdateBy = null
    obj.HempLicenseDocument = null
    return obj
  }
}
