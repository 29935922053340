import Document from './Document'
import HempLicense from './HempLicense'
import HempLicenseArea from './HempLicenseArea'
export default class HempLicenseDocument {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //HempLicenseID is required
      HempLicenseID: 0,
      //DocumentID is required
      DocumentID: 0,
      HempLicenseAreaID: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.HempLicenseID = obj.HempLicenseID
    this.DocumentID = obj.DocumentID
    this.HempLicenseAreaID = obj.HempLicenseAreaID
    this.Document = new Document(obj.Document || {})
    this.HempLicense = new HempLicense(obj.HempLicense || {})
    this.HempLicenseArea = new HempLicenseArea(obj.HempLicenseArea || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Document = null
    obj.HempLicense = null
    obj.HempLicenseArea = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Document = null
    obj.HempLicense = null
    obj.HempLicenseArea = null
    return obj
  }
}
