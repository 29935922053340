import HempLicense from './HempLicense'
import User from './User'
export default class KeyParticipants {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //HempLicenseID is required
      HempLicenseID: 0,
      //Name is required
      Name: '',
      //Title is required
      Title: '',
      //InsertByID is required
      InsertByID: 0,
      //InsertDate is required
      InsertDate: new Date().toISOString().split('T')[0],
      //UpdateByID is required
      UpdateByID: null,
      //UpdateDate is required
      UpdateDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.HempLicenseID = obj.HempLicenseID
    this.Name = obj.Name
    this.Title = obj.Title
    this.InsertByID = obj.InsertByID
    this.InsertDate = obj.InsertDate
    this.UpdateByID = obj.UpdateByID
    this.UpdateDate = obj.UpdateDate
    this.HempLicense = new HempLicense(obj.HempLicense || {})
    this.InsertBy = new User(obj.InsertBy || {})
    this.UpdateBy = new User(obj.UpdateBy || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.HempLicense = null
    obj.InsertBy = null
    obj.UpdateBy = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.HempLicense = null
    obj.InsertBy = null
    obj.UpdateBy = null
    return obj
  }
}
